.App {
  text-align: center;
}

.header-section {
  padding: 15px;
  border-bottom: #000;
  border-bottom-style: ridge;
  border-bottom-width: medium;
  justify-content: space-between;
  display: inline-flex;
  border-bottom-width: medium;
  width: -webkit-fill-available;
}

.header-branding {
  display: -webkit-inline-flex;
  align-items: center;
}

.header-icons {
  display: -webkit-inline-flex;
  align-items: center;
  float: right;
}

a.subtle-button {
  background-color: rgb(194, 194, 194);
  color: black;
  padding: 8px;
  border-radius: 45%;
  font-size: calc(10px + 2vmin);
  border-width: 0px;
  margin: 8px;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
}

a:hover.subtle-button {
  background-color: rgb(62, 62, 62);
  color: white;

}

a:active.subtle-button {
  background-color: rgb(153, 153, 153);

}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.column-stack {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: right;
  justify-content: right;
}

.row-stack {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  font-size: small;
  padding: 5px;
  border-radius: 6px;
  
  position: absolute;
  z-index: -1;
  width: 70px;
  top: 110%;
  left: 50%;
  margin-left: -40px;
 
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
